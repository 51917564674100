@import "./theme/variables";
@import "./theme/colors";

.main__wrapper {
  height: 100vh;
}

.ant-layout-has-sider {
  background: $sider-background;
  .app__overlay {
    width: calc(100% - #{$sider-width});
    left: $sider-width;
  }
}

.app__overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  z-index: 9999;
  top: 0;
  left: 0;
}

.app__loading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -10px;
  margin-top: -13px;
}

.main__content__layout {
  background: #f0f2f5 !important;
}

.page__container {
  padding: 30px;
}

.ant-scroll-number.ant-badge-count {
  z-index: 999;
}

.emoji-mart {
  .emoji-mart-search {
    display: none;
  }
  .emoji-mart-category-label {
    font-size: 12px;
  }
}
.ant-table {
  thead tr th {
    background: none;
    border: none;
  }
  tbody {
    tr {
      td {
        border-bottom: 1px solid $divider;
      }
    }
  }

  .ant-table-tbody {
    tr:not(.ant-table-placeholder) {
      &:hover {
        td {
          background-color: $sider-background !important;
        }
      }
    }
  }
}

.ant-menu-vertical {
  border: none !important;
}

.light__button {
  background-color: #f4f8fb !important;
  color: #0071f2;
  border: none;
  span {
    box-shadow: none !important;
  }
  &:hover,
  &:focus {
    background-color: #dceaff !important;
    color: #0071f2;
  }
}

button {
  font-size: 12px !important;
}

input {
  font-size: 12px !important;
}

.search__input.ant-input-affix-wrapper {
  border-radius: 24px !important;
  border: 1px solid $divider !important;
  margin-bottom: 12px;
  input::placeholder {
    color: #7ba3c5;
  }
  .anticon {
    color: $secondary-color;
  }
}

.box__shadow {
  background: #fbfbfb;
  box-shadow: 0px 2.08325px 1.76623px rgba(0, 113, 242, 0.0209047),
    0px 5.75991px 4.88341px rgba(0, 113, 242, 0.03),
    0px 13.8677px 11.7574px rgba(0, 113, 242, 0.0390953),
    16px 24px 32px rgba(0, 113, 242, 0.1), inset 8px 8px 12px #ffffff,
    inset -6px -6px 12px rgba(237, 243, 252, 0.8);
}
